/*================================================
Default CSS
=================================================*/
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
}
a {
  text-decoration: none;
  transition: 0.5s;
  color: #212121;
}
a:hover, a:focus {
  color: #086AD8;
  text-decoration: none;
}
button {
  outline: 0 !important;
}
p {
  margin-bottom: 15px;
  line-height: 1.8;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
p:last-child {
  margin-bottom: 0;
}
i {
  line-height: normal;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
img {
  max-width: 100%;
  height: auto;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}
.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #212121;
  text-transform: capitalize;
  font-family: "Catamaran", sans-serif;
  font-weight: bold;
}
.h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0;
}
.default-btn {
  display: inline-block;
  padding: 12px 35px 13px;
  background-color: #086AD8;
  color: #ffffff;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 600;
}
.default-btn:hover {
  background-color: #10dce8;
  color: #ffffff;
}
.optional-btn {
  display: inline-block;
  padding: 13px 35px 14px;
  background-color: #10dce8;
  color: #ffffff;
  border-radius: 2px;
  margin-left: 16px;
  font-size: 15px;
  font-weight: 500;
}
.optional-btn:hover {
  background-color: #086AD8;
  color: #ffffff;
}
.section-title {
  text-align: center;
  margin-top: -5px;
  margin-bottom: 60px;
}
.section-title h2 {
  font-size: 40px;
  margin-bottom: 12px;
  position: relative;
  line-height: 1;
}
.section-title span {
  color: #086AD8;
}
.section-title p {
  max-width: 600px;
  margin: auto;
}
.section-title .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  margin: 20px auto 0;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.section-title .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(85px);
  }
}
.default-shape .shape-1 {
  position: absolute;
  right: 10%;
  top: 16%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.default-shape .shape-2 {
  position: absolute;
  left: 10%;
  top: 16%;
  z-index: -1;
}
.default-shape .shape-2 img {
  animation-name: rotateMe;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.default-shape .shape-3 {
  position: absolute;
  left: 5%;
  bottom: 25%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.default-shape .shape-4 {
  position: absolute;
  right: 10%;
  bottom: 25%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.default-shape .shape-5 {
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
/*================================================
Preloader Area CSS
=================================================*/
.preloader-area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #086AD8;
  z-index: 999999;
}
.preloader-area .preloader {
  width: 80px;
  height: 80px;
  display: inline-block;
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.preloader-area .preloader span {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #ffffff;
  animation: preloader 1.3s linear infinite;
}
.preloader-area .preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}
@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-image: url('bg.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-area::before {
  z-index: -1;
  background: #000000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.8;
}
.coming-soon-area .social-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  bottom: 30px;
}
.coming-soon-area .social-list li {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}
.coming-soon-area .social-list li.list-heading {
  display: block;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.coming-soon-area .social-list li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #086AD8;
  border: 1px solid #086AD8;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
}
.coming-soon-area .social-list li a:hover {
  background-color: transparent;
  color: #086AD8;
  transition: 0.6s;
  -webkit-transition: 0.5s;
}
.coming-soon-content {
  text-align: center;
  max-width: 820px;
  margin: -80px auto 0;
}
.coming-soon-content h1 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 46px;
  font-weight: 700;
}
.coming-soon-content p {
  color: #ffffff;
  font-size: 14px;
  margin: 0 auto;
  max-width: 620px;
  line-height: 30px;
}
.coming-soon-content form {
  position: relative;
  margin: 35px auto 55px;
  max-width: 520px;
}
.coming-soon-content form .email-input {
  display: block;
  width: 100%;
  height: 56px;
  border: none;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  padding: 15px 25px;
  outline: 0 !important;
  background: #F1F2F3;
}
.coming-soon-content form .submit-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 50px;
  background: #086AD8;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  width: 130px;
  outline: 0 !important;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.coming-soon-content form .submit-btn:hover, .coming-soon-content form .submit-btn:focus {
  background-color: #086AD8;
}
.coming-soon-content #timer div {
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  color: #086AD8;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
  margin: 0 10px;
  padding-top: 18px;
  font-size: 40px;
  font-weight: 700;
}
.coming-soon-content #timer div span {
  display: block;
  margin-top: -4px;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
}
/*================================================
Fooetr Area CSS
=================================================*/
.footer-section {
  background-color: #f5f3f3;
}
.subscribe-area {
  margin-bottom: 50px;
}
.subscribe-area .subscribe-content h2 {
  font-size: 35px;
  margin-bottom: 0;
}
.subscribe-area .newsletter-form {
  position: relative;
}
.subscribe-area .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: 60px;
  padding-left: 25px;
  border-radius: 10px;
  outline: 0;
  color: #212121;
}
.subscribe-area .newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #666666;
}
.subscribe-area .newsletter-form .input-newsletter::placeholder {
  color: #666666;
}
.subscribe-area .newsletter-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #086AD8;
  color: #ffffff;
  border: none;
  height: 50px;
  padding: 0 30px 1px;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
}
.subscribe-area .newsletter-form button:hover {
  background-color: #10dce8;
  color: #ffffff;
}
.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget .footer-heading {
  margin-bottom: 20px;
}
.single-footer-widget .footer-heading h3 {
  font-size: 22px;
}
.single-footer-widget .footer-social {
  padding-left: 0;
  margin-bottom: 0;
}
.single-footer-widget .footer-social li {
  display: inline-block;
  margin-right: 10px;
  list-style-type: none;
}
.single-footer-widget .footer-social li a {
  display: block;
  width: 35px;
  height: 35px;
  background-color: #e5e3e3;
  border-radius: 4px;
  line-height: 35px;
  text-align: center;
  color: #212121;
}
.single-footer-widget .footer-social li a:hover {
  background-color: #086AD8;
  color: #ffffff;
}
.single-footer-widget .footer-social li a .flaticon-facebook, .single-footer-widget .footer-social li a .flaticon-twitter, .single-footer-widget .footer-social li a .flaticon-pinterest {
  position: relative;
  top: 0.5px;
}
.single-footer-widget .footer-social li:last-child {
  margin-right: 0;
}
.single-footer-widget .footer-quick-links {
  padding-left: 0;
  margin-bottom: 0;
}
.single-footer-widget .footer-quick-links li {
  list-style-type: none;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 600;
}
.single-footer-widget .footer-quick-links li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-info-contact {
  position: relative;
  padding-left: 40px;
  line-height: 1.8;
  margin-bottom: 15px;
}
.single-footer-widget .footer-info-contact:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-info-contact i {
  position: absolute;
  left: 0;
  font-size: 25px;
  color: #086AD8;
}
.single-footer-widget .footer-info-contact h3 {
  margin-bottom: 3px;
  font-size: 18px;
}
.single-footer-widget .footer-info-contact span {
  font-size: 15px;
}
.copyright-area {
  background-color: #212121;
  padding-top: 25px;
  padding-bottom: 25px;
}
.copyright-area .copyright-area-content p {
  color: #ffffff;
}
.copyright-area .copyright-area-content p i {
  position: relative;
  top: 1px;
}
.copyright-area .copyright-area-content p a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
}
.copyright-area .copyright-area-content p a:hover {
  color: #086AD8;
}
.copyright-area .copyright-area-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: right;
}
.copyright-area .copyright-area-content ul li {
  display: inline-block;
  position: relative;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
}
.copyright-area .copyright-area-content ul li a {
  color: #ffffff;
  display: inline-block;
}
.copyright-area .copyright-area-content ul li a:hover {
  color: #086AD8;
}
.copyright-area .copyright-area-content ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -11px;
  width: 1px;
  height: 14px;
  background-color: #ffffff;
}
.copyright-area .copyright-area-content ul li:last-child {
  margin-right: 0;
}
.copyright-area .copyright-area-content ul li:last-child::before {
  display: none;
}
.copyright-area .copyright-area-content ul li:first-child {
  margin-left: 0;
}
/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: #086AD8;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
  border-radius: 50%;
  border: 1px solid #086AD8;
}
.go-top.active {
  top: 98%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 0.5s;
  font-size: 30px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #212121;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  border-radius: 50%;
}
.go-top:hover, .go-top:focus {
  color: #ffffff;
}
.go-top:hover::before, .go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child, .go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child, .go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
/*================================================
Navbar Area CSS
=================================================*/
.navbar-area .navbar {
  transition: 0.5s;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.navbar-area .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar-area .navbar .navbar-nav {
  margin-left: auto;
}
.navbar-area .navbar .navbar-nav .nav-item {
  position: relative;
  padding: 0;
}
.navbar-area .navbar .navbar-nav .nav-item a {
  color: #212121;
  text-transform: capitalize;
  font-size: 15.5px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 14px;
  margin-right: 14px;
}
.navbar-area .navbar .navbar-nav .nav-item a:hover, .navbar-area .navbar .navbar-nav .nav-item a:focus, .navbar-area .navbar .navbar-nav .nav-item a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -2px;
  margin-right: -4px;
}
.navbar-area .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.navbar-area .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.navbar-area .navbar .navbar-nav .nav-item:hover a, .navbar-area .navbar .navbar-nav .nav-item.active a {
  color: #086AD8;
  transition: 0.5s;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  position: relative;
  color: #212121;
  font-size: 14.5px;
  font-weight: 600;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #086AD8;
  letter-spacing: 1px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  display: none;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -255px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #212121;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #086AD8;
}
.navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  margin-top: 0;
}
.navbar-area .navbar .others-options {
  margin-left: auto;
}
.navbar-area .navbar .others-options .option-item {
  color: #212121;
  display: inline-block;
  position: relative;
  line-height: 1;
}
.navbar-area .navbar .others-options .option-item .search-btn {
  cursor: pointer;
  transition: 0.5s;
  color: #212121;
  font-size: 20px;
  font-weight: 600;
}
.navbar-area .navbar .others-options .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: 0.5s;
  width: 20px;
  color: #212121;
  font-size: 18px;
}
.navbar-area .navbar .others-options .option-item .close-btn.active {
  display: block;
}
.navbar-area .navbar .others-options .burger-menu {
  width: 35px;
  height: auto;
  background-color: transparent;
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 1px;
}
.navbar-area .navbar .others-options .burger-menu span {
  display: block;
  width: 35px;
  height: 3px;
  background: #212121;
  transition: 0.5s;
  border-radius: 30px;
  margin-left: auto;
  margin-bottom: 4px;
}
.navbar-area .navbar .others-options .burger-menu span:nth-child(3) {
  width: 28px;
  margin-left: auto;
  margin-bottom: 0;
}
.navbar-area .navbar .others-options .burger-menu:hover span {
  background: #086AD8;
}
.navbar-area .navbar .others-options .burger-menu:hover span:nth-child(3) {
  width: 35px;
}
.navbar-area .navbar .others-options .default-btn {
  margin-left: 20px;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.navbar-area.navbar-style-two {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}
.navbar-area.navbar-style-two.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.navbar-area.navbar-style-two.bg-color {
  background-color: rgba(255, 255, 255, 0.5);
}
.search-overlay {
  display: none;
}
.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 23px;
}
.search-overlay.search-popup .search-form {
  position: relative;
}
.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #212121;
  outline: 0;
  transition: 0.5s;
  padding-top: 4px;
  padding-left: 10px;
}
.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #086AD8;
}
.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: #666666;
  transition: 0.5s;
  padding: 0;
}
.search-overlay.search-popup .search-form .search-button:hover, .search-overlay.search-popup .search-form .search-button:focus {
  color: #086AD8;
}
.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  overflow: hidden;
}
.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  width: 30%;
  overflow-y: scroll;
  height: 100%;
  background-color: #ffffff;
  transition: 0.7s;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.sidebar-modal .sidebar-modal-inner .close-btn {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 20px;
  transition: 0.5s;
  color: #212121;
  opacity: 0.66;
  cursor: pointer;
}
.sidebar-modal .sidebar-modal-inner .close-btn:hover {
  opacity: 1;
}
.sidebar-modal .sidebar-about-area {
  margin-bottom: 40px;
}
.sidebar-modal .sidebar-about-area .title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.sidebar-modal .sidebar-about-area .title p {
  margin-top: 15px;
  font-size: 15px;
}
.sidebar-modal .sidebar-contact-feed {
  margin-bottom: 40px;
}
.sidebar-modal .sidebar-contact-feed h2 {
  margin-bottom: 25px;
  font-size: 22px;
}
.sidebar-modal .sidebar-contact-feed .contact-form {
  padding: 0;
  box-shadow: unset;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-group {
  margin-bottom: 15px;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control {
  height: 50px;
  padding: 0 15px;
  line-height: initial;
  color: #212121;
  background-color: transparent;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: unset !important;
  font-size: 14px;
  font-weight: 400;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control:focus {
  border-color: #086AD8;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control:-ms-input-placeholder {
  color: #666666;
}
.sidebar-modal .sidebar-contact-feed .contact-form form .form-control::placeholder {
  color: #666666;
}
.sidebar-modal .sidebar-contact-feed .contact-form form textarea.form-control {
  height: auto !important;
  padding-top: 15px;
}
.sidebar-modal .sidebar-contact-feed .contact-form .send-btn {
  margin-top: 10px;
}
.sidebar-modal .sidebar-contact-feed .contact-form .send-btn .send-btn-one {
  display: inline-block;
  padding: 14px 35px;
  background-color: #086AD8;
  color: #ffffff;
  border-radius: 2px;
  transition: 0.5s;
  border: none;
  font-size: 15px;
  font-weight: 600;
}
.sidebar-modal .sidebar-contact-feed .contact-form .send-btn .send-btn-one:hover {
  background-color: #10dce8;
  color: #ffffff;
}
.sidebar-modal .sidebar-contact-area .contact-info {
  text-align: center;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 {
  margin-bottom: 0;
  text-transform: lowercase;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a {
  display: inline-block;
  color: #086AD8;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:hover {
  color: #212121;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
  color: #212121;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: #086AD8;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 span {
  display: block;
  color: #666666;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 4px;
  padding-left: 0;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 32px;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #aba5a5;
  display: block;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a:hover {
  color: #ffffff;
  border-color: #086AD8;
  background-color: #086AD8;
}
.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
}
.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}
@media only screen and (max-width: 767px) {
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area .navbar-light .navbar-toggler {
    border-color: #212121;
    border-radius: 0;
  }
  .navbar-area .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-area .collapse:not(.show).active {
    display: block;
  }
  .navbar-area .navbar .navbar-nav {
    margin: 15px 0 0;
    background-color: #086AD8;
    padding: 5px 15px;
    height: 350px;
    overflow-y: scroll;
  }
  .navbar-area .navbar .navbar-nav .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-area .navbar .navbar-nav .nav-item a {
    font-size: 15px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    color: #ffffff !important;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
    box-shadow: unset;
    position: relative;
    background-color: transparent !important;
    border: none;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    width: 100%;
    z-index: 99;
    display: block;
    opacity: 1;
    visibility: visible;
    padding: 5px 20px 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
    padding-left: 0 !important;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
  }
  .navbar-area .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    top: 0;
  }
  .navbar-area .navbar .others-options {
    display: none;
  }
}
/*================================================
Contact Section CSS
=================================================*/
.single-contact-box {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
}
.single-contact-box i {
  line-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px dashed #086AD8;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
  color: #086AD8;
  transition: all 0.5s;
  margin-bottom: 20px;
}
.single-contact-box:hover i {
  background-color: #086AD8;
  color: #ffffff;
}
.single-contact-box .content-title h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.single-contact-box .content-title a {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}
.single-contact-box .content-title a:hover {
  color: #086AD8;
}
/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.page-title-area.item-bg-1 {
  background-image: url('bg.jpg');
}
.page-title-area.item-bg-2 {
  background-image: url('bg-2.jpg');
}
.page-title-area.item-bg-3 {
  background-image: url('bg-4.jpg');
}
.page-title-area.item-bg-4 {
  background-image: url('bg-5.jpg');
}
.page-title-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.77;
}
.page-title-content {
  text-align: center;
  margin-top: 0;
}
.page-title-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 45px;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}
.page-title-content ul li {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  margin-left: 13px;
  margin-right: 13px;
}
.page-title-content ul li a {
  display: inline-block;
  color: #ffffff;
  transition: 0.5s;
}
.page-title-content ul li a:hover {
  color: #086AD8;
}
.page-title-content ul li::before {
  content: "";
  position: absolute;
  right: -15.5px;
  top: 11px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #086AD8;
}
.page-title-content ul li:last-child::before {
  display: none;
}
/*================================================
Contact Area CSS
=================================================*/
.contact-text {
  margin-bottom: 30px;
}
.contact-text h2 {
  margin-bottom: 10px;
  font-size: 40px;
}
.contact-text h2 span {
  color: #086AD8;
}
.contact-form {
  background-color: #ffffff;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  padding: 35px;
}
.contact-form form .form-group {
  margin-bottom: 15px;
}
.contact-form form .form-control {
  height: 48px;
  padding: 0 15px;
  line-height: initial;
  color: #212121;
  background-color: #f8f6f6;
  border: 1px solid #f8f6f6;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: unset !important;
  font-size: 14px;
  font-weight: 400;
}
.contact-form form .form-control:focus {
  border-color: #086AD8;
}
.contact-form form .form-control:-ms-input-placeholder {
  color: #999999;
}
.contact-form form .form-control::placeholder {
  color: #999999;
}
.contact-form form textarea.form-control {
  height: auto !important;
  padding-top: 15px;
}
.contact-form .send-btn {
  margin-top: 10px;
  transition: 0.5s;
}
.contact-form .send-btn .default-btn {
  border: none;
  display: inline-block;
  width: 100%;
}
.contact-form .with-errors ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}
.contact-form .with-errors ul li {
  color: red;
}
.contact-form #msgSubmit {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
}
.contact-form #msgSubmit.text-danger {
  margin-top: 20px !important;
  font-family: "Open Sans", sans-serif;
}
/*================================================
About Area CSS
=================================================*/
.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}
.about-tab {
  max-width: 620px;
  padding: 100px 0;
  margin-left: 50px;
}
.about-tab h2 {
  font-size: 40px;
  margin-bottom: 14px;
}
.about-tab .bar {
  height: 4px;
  width: 85px;
  background: #086AD8;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}
.about-tab .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #ffffff;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
}
.about-tab .about-list-tab .tabs {
  border-bottom: 1px solid #eeeeee;
  padding-left: 0;
  margin-bottom: 30px;
  margin-top: 20px;
}
.about-tab .about-list-tab .tabs li {
  padding: 0;
  margin-right: 30px;
  padding-bottom: 10px;
  display: inline-block;
}
.about-tab .about-list-tab .tabs li.current {
  border-bottom: 2px solid #086AD8;
}
.about-tab .about-list-tab .tabs li.current a {
  color: #086AD8;
}
.about-tab .about-list-tab .tabs li a {
  position: relative;
  display: block;
  font-weight: 600;
}
.about-tab .about-list-tab .tab_content .tabs_item .text h3 {
  font-size: 22px;
  margin-bottom: 0;
}
.about-tab .about-list-tab .tab_content .tabs_item .list {
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.about-tab .about-list-tab .tab_content .tabs_item .list li {
  list-style-type: none;
  margin-bottom: 12px;
  font-weight: 600;
}
.about-tab .about-list-tab .tab_content .tabs_item .list li:last-child {
  margin-bottom: 0;
}
.about-tab .about-list-tab .tab_content .tabs_item .list i {
  color: #086AD8;
  margin-right: 5px;
}
.about-tab .about-list-tab .tab_content .tabs_item p {
  margin-bottom: 0;
}
.about-tab .about-list-tab .tab_content .tabs_item .default-btn {
  margin-top: 30px;
}
.about-image {
  height: 100%;
  background-image: url('about-2.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.about-image img {
  display: none;
}
/*================================================
Services Area CSS
=================================================*/
.services-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.services-section.bg-color {
  background-color: #f0f4f8;
  border-bottom: 1px solid #f3f3f3;
}
.services-section.bg-background {
  background-image: url('services-bg.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.services-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.single-services-box {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-box .icon {
  transition: 0.5s;
  display: inline-block;
  height: 70px;
  width: 90px;
  line-height: 70px;
  background-color: #086AD8;
  text-align: center;
  font-size: 30px;
  color: #ffffff;
  border-radius: 30px 5px 30px 5px;
  transition: 0.5s;
}
.single-services-box .icon.bg-deb0fe {
  background-color: #deb0fe;
  color: #ffffff;
}
.single-services-box .icon.bg-79e8e2 {
  background-color: #79e8e2;
  color: #ffffff;
}
.single-services-box .icon.bg-fcc774 {
  background-color: #fcc774;
  color: #ffffff;
}
.single-services-box .icon.bg-84b7fd {
  background-color: #84b7fd;
  color: #ffffff;
}
.single-services-box .icon.bg-fe929f {
  background-color: #fe929f;
  color: #ffffff;
}
.single-services-box .icon.bg-2e1342 {
  background-color: #2e1342;
  color: #ffffff;
}
.single-services-box h3 {
  font-size: 22px;
  transition: 0.5s;
  margin-top: 25px;
  margin-bottom: 8px;
}
.single-services-box p {
  margin-bottom: 0;
  transition: 0.5s;
}
.single-services-box .read-btn {
  display: inline-block;
  margin-top: 15px;
  font-weight: 600;
  font-size: 15px;
}
.single-services-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  transition: 0.5s;
  border-radius: 5px;
}
.single-services-box:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-box:hover::before {
  height: 100%;
}
.single-services-box:hover h3 {
  color: #ffffff;
}
.single-services-box:hover p {
  color: #ffffff;
}
.single-services-box:hover .read-btn {
  color: #ffffff;
}
.single-services-box:hover .icon {
  border-radius: 5px 30px 5px 30px;
  background-color: #ffffff !important;
  color: #086AD8 !important;
}
.single-services-two {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  border: 1px dashed #086AD8;
}
.single-services-two .icon {
  color: #086AD8;
  font-size: 50px;
  transition: 0.5s;
}
.single-services-two h3 {
  font-size: 25px;
  transition: 0.5s;
  margin-top: 15px;
  margin-bottom: 10px;
}
.single-services-two p {
  margin-bottom: 0;
  transition: 0.5s;
}
.single-services-two .read-btn {
  display: inline-block;
  margin-top: 15px;
  font-weight: 600;
  font-size: 15px;
}
.single-services-two::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 0%;
  background: #086AD8;
  border-radius: 0;
  transition: 0.5s;
  border-radius: 10px;
}
.single-services-two:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two:hover::before {
  height: 100%;
}
.single-services-two:hover h3 {
  color: #ffffff;
}
.single-services-two:hover p {
  color: #ffffff;
}
.single-services-two:hover .read-btn {
  color: #ffffff;
}
.single-services-two:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-fc9ba7 {
  border: 1px dashed #fc9ba7;
}
.single-services-two.bg-fc9ba7 .icon {
  color: #fc9ba7;
}
.single-services-two.bg-fc9ba7::before {
  background: #fc9ba7;
}
.single-services-two.bg-fc9ba7:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-fc9ba7:hover::before {
  height: 100%;
}
.single-services-two.bg-fc9ba7:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-2cdcf7 {
  border: 1px dashed #2cdcf7;
}
.single-services-two.bg-2cdcf7 .icon {
  color: #2cdcf7;
}
.single-services-two.bg-2cdcf7::before {
  background: #2cdcf7;
}
.single-services-two.bg-2cdcf7:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-2cdcf7:hover::before {
  height: 100%;
}
.single-services-two.bg-2cdcf7:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-009af0 {
  border: 1px dashed #009af0;
}
.single-services-two.bg-009af0 .icon {
  color: #009af0;
}
.single-services-two.bg-009af0::before {
  background: #009af0;
}
.single-services-two.bg-009af0:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-009af0:hover::before {
  height: 100%;
}
.single-services-two.bg-009af0:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-f4d62c {
  border: 1px dashed #f4d62c;
}
.single-services-two.bg-f4d62c .icon {
  color: #f4d62c;
}
.single-services-two.bg-f4d62c::before {
  background: #f4d62c;
}
.single-services-two.bg-f4d62c:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-f4d62c:hover::before {
  height: 100%;
}
.single-services-two.bg-f4d62c:hover .icon {
  color: #ffffff;
}
.single-services-two.bg-1e2d75 {
  border: 1px dashed #1e2d75;
}
.single-services-two.bg-1e2d75 .icon {
  color: #1e2d75;
}
.single-services-two.bg-1e2d75::before {
  background: #1e2d75;
}
.single-services-two.bg-1e2d75:hover {
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
}
.single-services-two.bg-1e2d75:hover::before {
  height: 100%;
}
.single-services-two.bg-1e2d75:hover .icon {
  color: #ffffff;
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes moveScale {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  margin: 0 5px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 45px;
  color: #212121;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 18px;
  font-weight: 700;
}
.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
  background: #086AD8;
  color: #ffffff;
  box-shadow: 0 2px 10px 0 #d8dde6;
}
/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
  margin-bottom: 60px;
  margin-top: 60px;
}
.services-details-overview:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 24px;
}
.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 10px;
  font-size: 18px;
}
.services-details-overview .services-details-desc .services-details-accordion {
  margin-top: 25px;
}
.services-details-video {
  position: relative;
}
.services-details-video .details-video {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0;
  top: 40%;
  transform: translateY(-25%);
  margin: auto;
}
.services-details-video .details-video .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #086AD8;
  position: relative;
  z-index: 1;
  font-size: 35px;
}
.services-details-video .details-video .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear 1s infinite;
}
.services-details-video .details-video .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  animation: ripple 2s linear infinite;
}
.services-details-video .details-video .video-btn:hover, .services-details-video .details-video .video-btn .video-content .video-btn:focus {
  background-color: #086AD8;
  color: #ffffff;
}
.services-details-video .text {
  margin-top: 30px;
}
.services-details-video .text h3 {
  font-size: 24px;
  margin-top: 25px;
  margin-bottom: 10px;
}
/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion .card {
  display: block;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  background: #ffffff;
  margin-bottom: 15px;
  border-radius: 0;
  border: none;
}
.faq-accordion .card:last-child {
  margin-bottom: 0;
}
.faq-accordion .card .card-header {
  border-radius: 0 !important;
  padding: 0;
  border-bottom: none;
  background-color: #ffffff;
}
.faq-accordion .card .card-header a {
  padding: 12px 35px 10px 15px;
  color: #212121;
  text-decoration: none;
  position: relative;
  display: block;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
  font-weight: 600;
}
.faq-accordion .card .card-header a i {
  position: absolute;
  right: 15px;
  top: 50%;
  font-size: 20px;
  transform: rotate(180deg) translateY(50%);
}
.faq-accordion .card .card-header a.collapsed {
  border-bottom-color: transparent;
}
.faq-accordion .card .card-header a.collapsed i {
  transform: rotate(0) translateY(-50%);
}
.faq-accordion .card .collapse .card-body {
  position: relative;
  padding: 20px;
}
/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  font-size: 50px;
  font-family: "Open Sans", sans-serif;
  margin-top: 45px;
  margin-bottom: 15px;
}
.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}
/*================================================
Privacy Section CSS
=================================================*/
.conditions-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.privacy-policy {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.single-privacy h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.single-privacy h3:not(:first-child) {
  margin-top: 30px;
}